import "core-js/modules/es.array.push.js";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: "rideRecordDetail",
  props: ['sourceId', 'beginTime'],
  emits: ['parkRecordLoaded'],
  data() {
    return {
      AMap: null,
      map: null,
      centerLngLat: [104.07318, 31.662818],
      // 默认当前坐标为成都市坐标
      pathParam: [],
      //线路
      parkRecord: {},
      activeName: 'first',
      loading: true,
      refundModel: {
        reason: '',
        refundFee: 0
      },
      refundModelRules: {
        reason: [{
          required: true,
          message: '请输入退款原因',
          trigger: 'blur'
        }, {
          min: 1,
          max: 128,
          message: '长度在 1 到 128 个字符',
          trigger: 'blur'
        }],
        refundFee: [{
          required: true,
          message: '请输入退款金额',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    handleClick(tab) {
      if (tab.index === '1') {
        this.$api.business.rideRecord.getGPS({
          recordId: this.sourceId,
          beginTime: this.beginTime
        }).then(res => {
          if (res.code === 200) {
            res.data.forEach(v => {
              if (v.longitude > 0) this.pathParam.push([v.longitude, v.latitude]);
            });
            var oldLine = new window.AMap.Polyline({
              path: this.pathParam,
              strokeWeight: 8,
              strokeOpacity: 1,
              strokeColor: 'red',
              showDir: true
            });
            this.map.add(oldLine);
            this.map.setFitView();
          }
        });
      }
    },
    loadParkRecord() {
      this.$api.business.rideRecord.detail({
        id: this.sourceId,
        rideRecordTime: this.beginTime
      }).then(res => {
        if (res.code === 200) {
          this.parkRecord = res.data;
          this.$emit('parkRecordLoaded', this.parkRecord);
        } else if (res.code === 121401) {
          this.$api.business.rideCurrRecord.detail({
            id: this.sourceId
          }).then(res => {
            if (res.code === 200) {
              this.parkRecord = res.data;
              this.$emit('parkRecordLoaded', this.parkRecord);
            }
          });
        } else {
          ElMessage.error(res.message);
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    doRefund() {
      this.$refs.refundForm.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          this.$api.business.transactionFlow.refund({
            parkRecordId: this.parkRecord.id,
            arriveTime: this.parkRecord.arriveTime,
            departureTime: this.parkRecord.departureTime,
            refundFee: this.refundModel.refundFee,
            reason: this.refundModel.reason
          }).then(res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.refundDialogVisible = false;
            } else {
              ElMessage.error(res.message);
            }
          }).finally(() => {
            loading.close();
          });
        }
      });
    },
    refund() {
      if (this.parkRecord.paidFee <= 0) {
        ElMessage.error('停车记录没有已支付金额');
      } else {
        this.refundDialogVisible = true;
      }
    }
  },
  beforeMount() {
    this.loadParkRecord();
    AMapLoader.load({
      "key": this.$amapKey,
      // 申请好的Web端开发者Key，首次调用 load 时必填
      "version": "2.0",
      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      "plugins": ['AMap.Scale', 'AMap.PolyEditor', 'AMap.MouseTool', 'AMap.Polygon', 'AMap.Polyline'] // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then(AMap => {
      this.map = new AMap.Map("amapContainer", {
        resizeEnable: true
      });
    }).catch(e => {
      console.log('高德初始化失败', e);
    });
  }
};